@import "~@styles/variables";

.project-filter-form {
  font-size: 16px;
  
  h4 {
    margin-bottom: 24px;
    font-size: 18px;
    color: #aaa;
  }

  .badge {
    font-family: $font-header;
    font-weight: bold;
    color: white;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 4px 2px;
    border: 4px solid #888;
    box-shadow: 0 4px 2px -2px rgba(0,0,0,0.3);
    text-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    font-size: 12px;
  }

  .filter-type {
    margin-bottom: 30px;
    .badge {
      &:nth-of-type(1) { background-color: $yellow; }
      &:nth-of-type(2) { background-color: $green; }
      &:nth-of-type(3) { background-color: $blue; }
      &:nth-of-type(4) { background-color: $pink; }
    }
  }

  .filter-issue {
    margin-bottom: 30px;
    font-size: 20px;
    font-family: $font-header;
    font-weight: bold;
      
    .badge {
      background-color: $grey;
    }
  }
}
