@import "~@styles/variables";

.login-form {
  color: $grey;
  
  label {
    font-weight: bold;
    font-family: $font-header;
  }

  .btn {
    background-color: $green;
    border: 0px;
    font-weight: bold;
    font-family: $font-header;
  }
}