.tags-field-array {
  .tag {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;

    .form-group {
      margin-bottom: 15px;
    }
  }
}