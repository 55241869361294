@import "~@styles/variables";

.actor-marker {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  text-shadow:  2px 2px 0 white, 2px -2px 0 white, -2px 2px 0 white, -2px -2px 0 white, 2px 0px 0 white, 0px 2px 0 white, -2px 0px 0 white, 0px -2px 0 white;

  .icon {
    display: inline-block;
    font-size: 24px;
    margin: 0 0 5px -1px;
  }

  label {
    font-family: $font-header;
    font-size: 14px;
    font-weight: bold;
  }
}