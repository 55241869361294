@import '~@styles/variables.scss';
@import '~@styles/font.scss';

.profile-project-list-page {
  padding-top: 0px !important;

  #navbar {
    position: static;
    background: transparent;
  }

  .profile-project-topbar {
    margin-top: -80px;
    margin-bottom: 50px;
    color: $grey;
    
    .new-project-btn {
      padding: 5px 50px;
      border-radius: 12px;
      margin-bottom: 12px;
      border: 0px;
      background-color: $grey;
    }
  }


  .col-right {
    display: flex;
    justify-content: flex-end;
  }


  .page-title {
    color: $grey;
    margin-bottom: 24px;
  }

  .font-header {
    font-family: $font-header;
    font-weight: bold;
  }

  .project-list {
    .project {
      margin: 0px;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $grey-light;

      .project-name {
        color: $pink;
      }

      .project-meta {
        color: $grey;
      }

      .remove-btn, .hide-btn {
        display: inline-block;
        font-size: 20px;
        color: $grey-light;
        padding: 0px 12px;
      }

      .space-between {
        display: flex;
        justify-content: space-between;
      }

      .col {
        padding: 0px;
      }
    }
  }

  .no-project-panel {
    padding: 24px;
    background-color: #eee;
    border-radius: 12px;
    text-align: center;

    .new-project-btn {
      margin-top: 24px;
      padding: 10px 36px;
      background-color: #29A7DE;
    }
  }

}