.actor-profile-layout {
  .actor-profile-sidebar {
    border-right: 1px solid #ddd;
    text-align: right;

    .nav {
      .nav-link {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}