@import '~@styles/variables.scss';

.yt-btn {
  padding: 5px 50px;
  border-radius: 12px;
  margin-bottom: 12px;
  border: 0px;
  background-color: $grey;
  font-family: $font-header;
  font-weight: bold;
}

textarea.form-control {
  line-height: 1em;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}