.editable-tags-form {
  .edit {
    .form-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  .view {
    .tag {
      margin-bottom: 30px;
    }
  }
}