@import "~@styles/variables";

.project-list-page {
  .page-title {
    margin-bottom: 24px;
    color: $grey;
  }

  .filter-panel {
    padding-top: 24px;

    .badge {
      font-family: $font-header;
      font-weight: bold;
      color: white;
      margin-right: 15px;
      margin-bottom: 15px;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      text-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    }

    .filter-type {
      margin-bottom: 30px;
      .badge {
        &:nth-of-type(1) { background-color: $yellow; }
        &:nth-of-type(2) { background-color: $green; }
        &:nth-of-type(3) { background-color: $blue; }
        &:nth-of-type(4) { background-color: $pink; }
      }
    }

    .filter-issue {
      margin-bottom: 30px;
      .badge {
        background-color: $grey;
      }
    }
  }

  .project-list {
    .project {
      margin-bottom: 30px;
    }
  }
}