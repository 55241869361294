@import "~@styles/variables";

.actor-card {
  overflow: hidden;
  border-radius: 12px;
  transition: all 0.2s;

  &:hover {
    box-shadow: 5px 5px 30px rgba(0,0,0,0.3);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .card-thumbnail {
    padding-top: 66.67%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f0f0f0;
  }

  .card-body {
    padding: 20px;
    min-height: 160px;
    max-height: 200px;

  }

  .card-footer {
    background-color: white;
    border-top: 0px;
    padding: 20px;
  }

  .name {
    margin-bottom: 4px;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    color: $grey-dark;
    line-height: 1.5em;
  }

  .type {
    font-family: $font-header;
    font-weight: bold;
    font-size: 18px;
    color: $grey;
  }

  .issues {
    margin-top: 24px;
    .badge {
      font-family: $font-header;
      font-size: 14px;
      padding-top: 5px;
      padding-right: 8px;
      margin-right: 5px;
      margin-bottom: 5px;
      background-color: $grey-dark;
    }
  }


  .last-update {
    font-size: 16px;
    color: $grey;
  }
}

.issue-tooltip {
  font-size: 14px;
}
