@import "~@styles/variables";

.about-page {
  color: $grey;
  text-align: center;

  .main-container {
    padding-top: 120px;
  }

  section {
    margin-bottom: 120px;
  }

  .page-title {
    margin: auto;
    margin-bottom: 30px;
    background: rgb(70,131,74);
    background: linear-gradient(180deg, #FFB10F 0%, rgba(44,134,149,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

  .page-description {
    padding: 0px 120px;    
  }

  .cover-1-wrapper {
    margin: auto;
    max-width: $max-container-width;

    .cover-1 {
      position: relative;
      padding-top: 100%;
      border-radius: 50%;
      background-image: url('~@assets/images/contents/about-1.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center bottom;

      &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -10px;
        border-radius: inherit;
        background: linear-gradient(to right, #417289, #CA3D42);
      }      
          
    }
  }

  .actor-project-section {
    font-family: $font-header;
    font-weight: bold;
    color: $grey;

    .col {
      display: flex;
      align-items: center;
    }

    .project-total { order: 4; }

    .actor-total {
      color: $grey;

      big {
        background: rgb(247,164,15);
        background: linear-gradient(180deg, rgba(247,164,15,1) 0%, rgba(218,79,59,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .project-total {
      color: $grey;

      big {
        background: rgb(70,131,74);
        background: linear-gradient(180deg, rgba(70,131,74,1) 0%, rgba(44,134,149,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .left {
      display: block;
      text-align: left;
    }

    .right {
      display: block;
      text-align: right;
    }

    h4 {
      width: 100%;

      big {
        display: block;
        font-size: 8rem;
      }
    }
  }

  .outro-section {
    h2 {
      padding: 0px 120px;    
      color: #CA3D42;
    }
  }

}

