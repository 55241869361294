form {
  margin-bottom: 2rem;
}

.form-group.required {
  .form-label {
    &:after {
      content: "*";
      margin-left: 0.2rem;
      color: red;
    }
  }
}