@import '~@styles/variables';

.project-page {
  color: $text-color;

  .cover-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    max-width: 1920px;
    margin: auto;
  }

  .cover-wrapper {
    padding-right: 24px;
    width: 100%;
    flex-grow: 1;
  }

  .project-intro {
    width: 480px;
    flex-grow: 0;
  }

  .cover {
    width: 100%;
    height: 80vh;
    margin-bottom: 24px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
  }

  .actor-header {
    margin-bottom: 120px;
  }

  .actor-logo {
    height: 100px;
    margin-bottom: 24px;
  }

  .actor-logo-form {
    display: inline-block;
    width: 100px;
  }

  .actor-name {
    font-size: 48px;
    color: $purple;
  }

  .actor-meta {
    font-family: $font-header;
    font-weight: bold;
    font-size: 20px;
    color: $text-color;
  }

  .project-header {
    margin-bottom: 50px;
  }

  .project-name {
    color: $purple;
  }

  .project-meta {
    font-family: $font-header;
    font-weight: bold;
    color: $grey-light;
  }

  .section-title {
    color: $purple;
  }

  .actor-list-section {
    margin-bottom: 24px;

    .section-header {
      display: flex;
      justify-content: space-between;
    }

    .nav {
      margin: 0px -12px;

      li {
        padding: 12px;
        cursor: pointer;
        font-family: $font-header;
        font-weight: bold;
        color: $text-light-color;
        font-size: 20px;
      }

      .icon-book {
        padding-right: 10px;
      }      
    }

    .map-container {
      height: 420px;
      margin-bottom: 36px;
      border-radius: 12px;
      overflow: hidden;
    }
  }


  .info-section {
    margin-bottom: 80px;

    .info {
      margin-bottom: 50px;
    }
    .info-title {
      color: $purple;
    }

    .icon-col {
      flex-grow: 0;

      .icon {
        font-size: 56px;
      }
    }

    .issue-list {
      .issue {
        margin-bottom: 30px;

        .col {
          padding-left: 12px;
          padding-right: 12px;
        }

        .issue-name {
          margin-bottom: 0px;
          font-size: 24px;
        }
      }
    }

    .contacts {
      .contact {

        label {
          font-weight: bold;
        }

        .contact-detail {
          padding: 2px 4px;
          border: 1px solid $grey-light;
          border-radius: 4px;
          color: $grey;
          text-decoration: none;
        }
      }
    }

  }

  .gallery-section {
    .section-header {
      margin-bottom: 30px;
    }

    figure {
      cursor: pointer;
    }

    .add-gallery-area {
      display: flex;
      flex-direction: column;
      height: 200px;
      margin-bottom: 24px;
      border: 3px dashed $grey;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      font-family: $font-header;
      text-align: center;
    }

  }

}