@import "~@styles/variables";

.register-form {
  color: $grey;
  
  label {
    font-weight: bold;
    font-family: $font-header;
  }

  .btn {
    background-color: $blue;
    border: 0px;
    font-weight: bold;
    font-family: $font-header;
  }
}
