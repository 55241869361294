@import "~@styles/variables";

.homepage {

  .main-container {
    position: absolute;
    top: 120px; left: 0px; right: 0px; bottom: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 24px 24px 24px;
  }

  .sidebar {
    width: 294px;
    height: 100%;
    flex-grow: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 24px;
    padding-right: 12px;

    h2 {
      font-size: 18px;
      color: $grey;
      padding-bottom: 5px;
      margin-bottom: 24px;
      border-bottom: 1px solid $grey;

      .icon {
        margin-right: 5px;
      }
    }

    h4 {
      font-size: 18px;
      color: #aaa;
      margin-bottom: 24px;
    }
  }

  .map-container {
    margin-left: 12px;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
  }

  .description {
    margin-bottom: 30px;
    font-family: $font-header;
    line-height: 1.5rem;
  }

  .feed-slick {
    height: 200px;
    overflow: hidden;

    .actor {
      padding: 15px 0px;
      border-bottom: 1px solid #eee;

      .name {
        margin-bottom: 0px;
        font-size: .8rem;
      }

      .last-update {
        color: $grey-light;
        font-size: .7rem;
      }

      &:nth-child(4n+1) { .name { color: $pink; }}
      &:nth-child(4n+2) { .name { color: $green; }}
      &:nth-child(4n+3) { .name { color: $grey-dark; }}
      &:nth-child(4n) { .name { color: $blue; }}
    }
  }

  .mapboxgl-popup {
    // margin-top: -60px;
    width: 250px;

    .mapboxgl-popup-content {
      padding: 0;
      background-color: transparent;
    }
  
    .actor-card, .project-card {
      border: 0px;
      
      .card-body {
        padding: 10px;
        height: auto;
      }
  
      .card-footer {
        padding: 0px 10px 10px 10px;
      }
  
      .last-update {
        font-size: 12px;
      }

      .name {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

}