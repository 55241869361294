@import '~@styles/variables.scss';

.login-page {
  .login-form {
    max-width: 500px;
    margin: auto;
    margin-bottom: 12px;
  }

  .login-footer {
    text-align: center;
  }
}