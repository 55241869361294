.project-slick {
  margin: -12px -12px 12px -12px;

  .project-slick-slide-wrapper {
    padding: 24px 12px;
  }

  * {
    outline: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .slick-prev, .slick-next {
    &:before {
      color: black;
    }
  }

  .slick-track {
    margin-left: 0px;
  }
}