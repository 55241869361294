// container
$max-container-width: 928px;

// fonts
$font-header: 'Cloud';
$font-main: 'CM-Prasanmit';

// colors
$text-light-color: #888;
$text-color: #808080;

$pink: #D55780;
$yellow: #FFD909;
$green: #88C23F;
$blue: #29A7DE;
$purple: #8D469E;

$grey: #808080;
$grey-light: #C8C8C8;
$grey-dark: #727272;