@import "~@styles/variables";

@font-face {
  font-family: 'Cloud';
  font-weight: normal;
  src: url('~@assets/fonts/Cloud-Light.otf');
}

@font-face {
  font-family: 'Cloud';
  font-weight: bold;
  src: url('~@assets/fonts/Cloud-Bold.otf');
}

@font-face {
  font-family: 'CM-Prasanmit';
  font-weight: normal;
  src: url('~@assets/fonts/TEPC-CM-Prasanmit.ttf');
}

@font-face {
  font-family: 'CM-Prasanmit';
  font-weight: bold;
  src: url('~@assets/fonts/TEPC-CM-Prasanmit-Bol.ttf');
}

@font-face {
  font-family: 'CM-Prasanmit';
  font-weight: normal;
  font-style: italic;
  src: url('~@assets/fonts/TEPC-CM-Prasanmit-Ita.ttf');
}

@font-face {
  font-family: 'CM-Prasanmit';
  font-weight: bold;
  font-style: italic;
  src: url('~@assets/fonts/TEPC-CM-Prasanmit-Bol-Ita.ttf');
}

html, body {
  font-family: $font-main;
  font-size: 24px;
  line-height: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-header;
  font-weight: bold;
}

h4 {
  font-size: 1.2rem;
}

.font-header {
  font-family: $font-header;
  font-weight: bold;
}