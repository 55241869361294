@import '~@styles/font.scss';
@import '~@styles/components.scss';

#wrapper {
  padding-top: 120px;
}

.container {
  max-width: $max-container-width;
}

.logo {
  width: 100px;
  height: 100px;
  background-image: url('~@assets/images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}

#navbar {
  background: white;
  padding: 0px 24px;
}

#basic-navbar-nav {
  justify-content: space-between;

  .navbar-brand {
    margin-right: 2vw;
    padding: 10px 0px;
  }

  .navbar-nav {
    font-family: 'Cloud';
    font-weight: bold;
    font-size: 24px;

    .nav-link {
      padding: 5px 30px;
    }
  }

  .navbar-main {
    li:not(:last-child) {
      position: relative;
      &:after {
        content: '-';
        position: absolute;
        top: 5px;
        right: -5px;
        color: rgba(0,0,0,0.5);
      }
    }

  }

  .navbar-right {
    &:last-child {
      .nav-link {
        padding-right: 0px;
      }
    }
  }
}

#footer {
  padding: 120px 0px;
  text-align: center;

  .container {
    max-width: 600px;
  }

  .logo {
    width: 120px;
    height: 120px;
    margin: 0px auto 48px auto;
  }

  h4 {
    color: $grey;
  }
}

::-webkit-scrollbar {
  background: transparent;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 12px;
  border:4px solid #fff;  
}